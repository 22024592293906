import React, { useState, useEffect } from 'react';
import axios, { CancelToken, isCancel }  from 'axios';
import { API_URL } from '../utils/Constants';
import apiService from "../utils/apiService";

function FileUpload({ refreshPage }) {
  const [uploadFile, setUploadFile] = useState(null);
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  let [multipleFileProgress, setMultipleFileProgress] = useState(0); 
  let [ibytes,setIbytes] = useState(0);
  let [itotal,setITotal] = useState(0);
  const [uploadStarted, setUploadStarted] = useState(false);
  const [uploadCompleted, setUploadCompleted] = useState(false);
  const [userFeededData, setUserFeededData] = useState([]);
  const [userItems, setUserItems] = useState('');
  const controller = new AbortController();
  console.log(controller,"controllercontrollercontroller")

  const handleFileChange = (e) => {
    console.log(e.target.files[0]);
    setUploadFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    console.log(uploadFile);
    formData.append('filename', uploadFile);
    console.log(formData);
    for(var pair of formData.entries()) {
      console.log(pair[0]+', '+pair[1]);
    }
    //formData.append('file', file);
    try {
      //await axios.post(`${API_URL}/upload`, formData, { params });
      //refreshPage();
      setUploadStarted(true);
      console.log(formData)
      const headers = await apiService.acquireAccessToken();
      console.log(headers)
      let Newheaders = {
        headers:
        {
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin' : '*',
        'Authorization' : headers.headers.Authorization,
        }
      };

      let NewrequestConfig = {
        ...Newheaders,
        ...multipleFileOptions
        
      };
      console.log(NewrequestConfig);
      await axios.post(`${API_URL}/feedusers`,formData, NewrequestConfig).then(data =>{
        setUploadStarted(false);
        setUploadCompleted(true);
        console.log(data.data.user)
        setUserFeededData(data.data.user)
        
          return data;
      }).catch(err =>{
          console.log(err,"=========================qqqqqqqqqqqqqqqqqqwwwwwwwwwwwwwww");
          if(axios.isCancel(err))
          {
              console.log("I am cancelled");
          }
      })
    } catch(error) {
      console.error('error while uploading file', error);
    }
  };

  const cancelUpload = () => {
    console.log('Here......................')
    controller.abort();
    //console.log(source.token,"=====================")
    //source.cancel('Operation canceled by the user.');
    //source = CancelToken.source();
    //cancelPost();
    //console.log('here',controller.signal);
}

useEffect(() => {
  const items = [];
    (userFeededData).forEach((user,index) => {
          items.push(<tr>
            <td>{index+1}</td>
            <td style={{'word-wrap': 'break-word', 'width':'5%'}}>{user.peroot}</td>
            <td style={{'word-wrap': 'break-word', 'width':'80%'}}>{user.group}</td>
            <td style={{'word-wrap': 'break-word', 'width':'5%'}}>{user.status}</td>
          </tr>)
    });
    setUserItems(items); 
}, [userFeededData, uploadCompleted])

const multipleFileOptions = {
  onUploadProgress: (progressEvent) => {
      let {loaded, total} = progressEvent;
      let percentage = Math.floor(((loaded / 1000) * 100) / (total / 1000))+'%';
  
      setIbytes(loaded);
      setITotal(total);
      if(percentage === '100%')
      {
          // for UI Progress bar adjustment, taking max value as 99%, if it is 100% 
          percentage = '99%';
      }
      setMultipleFileProgress(percentage);
  },
  //cancelToken: new CancelToken( cancel =>  (cancelFileUpload.current = cancel) ),
  //signal: controller.signal
  signal: controller.signal
  // cancelToken: new CancelToken(function executor(c) { 
  //     cancelPost = c;
  //   })
  //headers: { "Content-Type": "multipart/form-data" }
}

  return (
    <div style={{width: "50%"}}>
      <br/>
      <br/>
      <form onSubmit={handleSubmit}>
        <input type="file" onChange={handleFileChange} />
        <button type="submit">Upload</button>
      </form>
      { uploadStarted &&
          <div>
            Upload In progress, please wait
          </div>
        }
        { uploadCompleted &&
          <div>
            Upload Completed
          </div>
        }
        
          <table border='1' width='100%'>
            <tr>
              <th>S.NO</th>
              <th>Peroot</th>
              <th>Directories</th>
              <th>Status</th>
            </tr>
            
              {userItems}     
        </table>
      {/* <button onClick={cancelUpload}>Cancel</button> */}
    </div>
  );
}

export default FileUpload;