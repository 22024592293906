import React from 'react';
import { BrowserRouter as Router} from 'react-router-dom';
import { MsalProvider } from '@azure/msal-react';
import './App.css';
import Root from './Root.js';

function App( { msalInstance }) {

  return (
    <MsalProvider instance={msalInstance}>
      <Router>
        <Root />
      </Router>
    </MsalProvider>
  );

}

export default App;
