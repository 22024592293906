const stg ={
    NAME: 'STG',
    API_URL: 'https://api.mediaconsole-stg.pearson.com/api',
    SOCKET_URL:'wss://socket.mediaconsole-stg.pearson.com/',
    CLIENT_ID: "5bd3186a-9085-45bd-814e-a530621f28f1",
    AUTHORITY: "209a71f6-c9bb-48f1-a23d-a26d275b75e2",
    API_SCOPES: "6246ddbd-da3d-4530-8521-c712666e69f2"
}

export default stg;
