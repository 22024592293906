import React, { useState, useEffect, useRef }  from 'react';
import { useDropzone } from 'react-dropzone';
import { useSearchParams } from "react-router-dom";
import './modal.css';
import UploadIcon from '../../../images/icon_upload.gif';
import PearsonLogo from '../../../images/logo.gif';
import ProgressBarGif from '../../../images/prog_bar.gif';
import ProgressBarTopGif from '../../../images/prog_top.gif';
import ProgressBarLeftGif from '../../../images/prog_left.gif';
import  { SOCKET_ENDPOINT } from '../../utils/Constants'

const BulkUploadPopup = props => {

    let divStyle = {
        display: props.displayModal ? 'block' : 'none'
    };
    let [searchParams, setSearchParams] = useSearchParams();

    function closeModal(e) {
      e.stopPropagation()
      props.onModalPopupStateChange(props.displayModal, e);
      if (searchParams.has('bulkupload')) {
        searchParams.delete('bulkupload');
        setSearchParams(searchParams);
        //dispatch(resetAppState({message:""}));
      }
	 }

   const { acceptedFiles, getRootProps, getInputProps } = useDropzone(
   {
    multiple: true,
    directory: true,
   }
   );

   let [uploadProgress, setUploadProgress] = useState('0%');
   const [ws, setWs] = useState(null);
   let [startTime, setStartTime] = useState(null);
   const [elapsedTime, setElapsedTime] = useState(0);
   const [timeRemaining, setTimeRemaining] = useState(null);
   const startTimeRef = useRef(startTime);
   let [netSpeed,setNetSpeed] = useState(0);
   let [ibytes,setIbytes] = useState(0);
   let [itotal,setITotal] = useState(0);
   let [fileUploadStartTime,setFileUploadStartTime] = useState('');
   //let [cummulativeLoadedSize, setCummulativeLoadedSize] = useState(0);
   let ibytesRef = useRef(ibytes);
   //let [cummulativeTotalSize, setCummulativeTotalSize] = useState(0);
   //let itotalRef = useRef(itotal);
   let [wsTransmittedReceivedData,setWsTransmittedReceivedData] = useState(0);
   let wsTransmittedReceivedDataRef = useRef(wsTransmittedReceivedData);
   let [displayFileSection, setDisplayFileSection] =useState(false);
   let fileName = [];
   let fileSize = [];
   let [successFileTableData,setSuccessFileTableData] = useState([]);


   
   
useEffect(() => {

    const newWs = new WebSocket(`${SOCKET_ENDPOINT}socket/`);
    //const newWs = new WebSocket("ws://localhost:8081/socket/");
    setWs(newWs);

    return () => {

        if(ws){
            ws.close();
        }
    };
},[]);

const sendFileInChunks = (file, index) => {
    if (!ws || ws.readyState !== WebSocket.OPEN) {
      console.error('WebSocket is not open or is not available.');
      return;
    }
    if(ws || ws.readyState == WebSocket.OPEN)
    {
      ws.onmessage = function incoming(data){
        data = JSON.parse(data.data)
        if(data.topic == 'time')
		      {
          startTimeRef.current = data.data;//new Date().getTime();
          setFileUploadStartTime(data.data);
        }else {
          wsTransmittedReceivedDataRef.current = data.data;
          setWsTransmittedReceivedData(wsTransmittedReceivedDataRef.current);
          const currentTime = new Date().getTime();
          const elapsed = (parseFloat(currentTime) - parseFloat(startTimeRef.current)) / 1000;
          setElapsedTime(elapsed);
        }
      }
    }
    // Send an initial message indicating the start of the file

    const chunkSize = 1024 * 1024 * 10; // 10MB chunks
    let offset = 0;

    const reader = new FileReader();


    reader.onload = () => {
        const chunkSize = 1024 * 1024 * 10; // 10MB chunks
        let offset = 0;

        const reader = new FileReader();
		
        reader.onload = (progressEvent) => {
          const { loaded, total } = progressEvent;
          
          ibytesRef.current = ibytesRef.current+loaded;
          setIbytes(ibytesRef.current);
          const chunk = reader.result;

          ws.send(chunk);

          offset += chunkSize;
          if(offset < file.size) {
            readNextChunk();
          } else {
              index = index + 1;
              sendEndMessage(file);
            if(acceptedFiles.length > index) {

                sendFileInChunks(acceptedFiles[index], index);
            }
			    }
        };

        const readNextChunk = () => {
          const blob = file.slice(offset, offset + chunkSize);
          reader.readAsArrayBuffer(blob);
        };

        readNextChunk();
    };

    const readNextChunk = () => {
        const blob = file.slice(offset, offset + chunkSize);
        reader.readAsArrayBuffer(blob);
      };
    sendStartMessage(file);
    readNextChunk();
  };

  const sendPath = () => {
    const sendWebSocketData = (data) => {
        if (ws && ws.readyState === WebSocket.OPEN) {
          ws.send(data);
        } else {
          console.error('WebSocket is not open or is not available.');
        }
      };
	     sendWebSocketData(`${props.currentDir} Bulk_Upload_path`);
  }

  const sendStartMessage = (file) => {
    const sendWebSocketData = (data) => {
        if (ws && ws.readyState === WebSocket.OPEN) {
          ws.send(data);
        } else {
          console.error('WebSocket is not open or is not available.');
        }
      };

    sendWebSocketData(`${file.path} Bulk_Upload_starts`);
  }

  const sendEndMessage = (file) => {
    const sendWebSocketData = (data) => {
        if (ws && ws.readyState === WebSocket.OPEN) {
          ws.send(data);
        } else {
          console.error('WebSocket is not open or is not available.');
        }
      };

      sendWebSocketData(`${file.path} Bulk_Upload_ends`);
	  
	  
  }

  const handleFileUpload = (e) => {
    e.preventDefault();

    const forbiddenExtensions = []; 
    let cummulateFileSizeToUpload = 0;
    acceptedFiles.map(file => {
      cummulateFileSizeToUpload += file.size;
      fileName.push(file.path);
      fileSize.push(file.size);
    }); 

    let sucessfileInfo = [];
            for (let index = 0; index < fileName.length; index++) {
                        
            let trColor = '';
            if(index % 2 === 0)
            {
                trColor = "backgroundColor:'#DDDDDD'";
            }
            sucessfileInfo.push(<tr align="left" key={index} style={{trColor}}>
                <td align="left" valign="middle" style={{border:"1px solid black"}}>{fileName[index]}</td>
                <td align="left" valign="middle" style={{border:"1px solid black"}}>{fileSize[index]}</td>
            </tr>);
          }
            
        setSuccessFileTableData(sucessfileInfo);

    if(cummulateFileSizeToUpload > 5 * 1024 * 1024 * 1024){
      alert("Files/Folders size exceeds 5GB. Please select content less than 5GB to upload.")
    }
    else{

    if ( acceptedFiles && acceptedFiles.length > 0) {

        const hasForbiddenExtensions = acceptedFiles.some(file => {
            const fileExtension = file.name.split('.').pop().toLowerCase();
            return forbiddenExtensions.includes(fileExtension);
        }
        );

        if(hasForbiddenExtensions){
            alert('You cannot upload files with these extensions')
        }
        else{
            sendPath();
            let selectedFileSize = acceptedFiles.reduce((total,file) => total + file.size, 0 )
            setITotal(selectedFileSize)
			           sendFileInChunks(acceptedFiles[0], 0);
            setUploadProgress('1%');
        }
    }

  }};

   const files = acceptedFiles.map(file => (
    <li key={file.path}>
          {file.path} - {file.size} bytes
    </li>
      ));

   const formatTime = (timeInSeconds) => {
    const hours = (!isNaN(timeInSeconds) && timeInSeconds != 'Infinity') ? Math.floor(timeInSeconds / 3600) : 0;
    const minutes = (!isNaN(timeInSeconds)  && timeInSeconds != 'Infinity') ? Math.floor((timeInSeconds % 3600) / 60) : 0;
    const seconds = (!isNaN(timeInSeconds)  && timeInSeconds != 'Infinity') ? Math.floor(timeInSeconds % 60) : 0;
    return `${String(hours).padStart(2, '0')} : ${String(minutes).padStart(2, '0')} : ${String(seconds).padStart(2, '0')}`;
   };

   useEffect(()=>{
     if(wsTransmittedReceivedData != 0)
        {
         let uploadPercentage = (wsTransmittedReceivedData/itotal) * 100 ;
            uploadPercentage = uploadPercentage + '%' ;
            setUploadProgress(uploadPercentage);
            if(uploadPercentage == '100%')
            {
              setDisplayFileSection(true);
            }
        }
            let bSpeed = elapsedTime ? wsTransmittedReceivedData / elapsedTime : 0;
            let bitSpeed = bSpeed * 8; // bps
            let kbitSpeed = (bitSpeed / 1000).toFixed(2);
            if(kbitSpeed)
                setNetSpeed(kbitSpeed);

            let bytesRemainaining = itotal - wsTransmittedReceivedData;
            let dtRemaining = 0;

            if(kbitSpeed)
            {
                dtRemaining = bytesRemainaining / kbitSpeed;
            }
            dtRemaining = Math.trunc(dtRemaining);
            setTimeRemaining(dtRemaining);

            

   },[itotal, wsTransmittedReceivedData, elapsedTime]);
   return (
        <div
        className="modal"
        onClick={ closeModal }
        style={divStyle}>
         <div
            className="modal-content"
            onClick={ e => e.stopPropagation() } >

<div style={{display : displayFileSection ? 'none' : 'block'}}>    

            <table border="0" width="100%" cellSpacing="3" cellPadding="0" className='modal-content-table'>
               <tr align="left" valign="top">
                   <td align="left" valign="middle"><span className="pagetitle">&nbsp;Bulk File Uploader</span></td>
                   <td align="right" valign="middle"><img src={PearsonLogo} title="Pearson Education" alt="Pearson Education" width="44" height="26" hspace="0" vspace="0" border="0"/></td>
                   <td><span
                           className="close" onClick={closeModal}
                           >&times;
                       </span>
                   </td></tr>
                   </table>

            <div id="table" style={{position:"absolute", top:"126px", width:"550px", height:"120px", zIndex:"1"}}>
                <table align="center" border="0" width="" cellSpacing="0" cellPadding="0">
                   <tr align="left" valign="top">
                       <td align="left" valign="top" width="90" style={{backgroundcolor: "#E9EAEC"}}><img src={UploadIcon} alt="Upload Files" width="91" height="70" hspace="0" vspace="0" border="0"/></td>
					   <td align="center" valign="middle"className="instructions">You may upload file of any size. <br/><br/>
                           File(s) uploaded will be located in the following directory:<br/>
                           <font style={{color:'red'}}>
                            {decodeURIComponent(props.currentDir)}
                            </font>
                           <br/><br/>

                   <form onSubmit={handleFileUpload}>
                       <div {...getRootProps()} style={dropzoneStyle}>
                           <input {...getInputProps()} />
                           <p>Drag and drop some files here, or
                               <span style={{ color: 'red' }}> click</span> to select files</p>
                           <aside>
                               <h4>Files</h4>
                               <ul>{files}</ul>
                           </aside>
                       </div>
                       { uploadProgress != '0%' &&
                       <table style={{border:'0',width:'100%', backgroundColor:"#E9EAEC",marginTop:"50px"}}>

                       { wsTransmittedReceivedData < itotal &&

                       (<tr align="center" style={{verticalAlign:"top"}}>
                           <td align="center" style={{verticalAlign:"middle"}} className="pagetitle" colSpan="2">File Upload in Progress, Please don't close window</td>
						     </tr>)
                      }

                        { wsTransmittedReceivedData == itotal &&

                        (<tr align="center" style={{verticalAlign:"top"}}>
                            <td align="center" style={{verticalAlign:"middle"}} className="pagetitle" colSpan="2">File Upload is Completed</td>
                        </tr>)
                        }

                       <tr align="left" style={{verticalAlign:"top"}} >
                       <td align="left" style={{verticalAlign:"middle"}} colSpan="2">

                       <table summary="This table displays the progress bar" border="0" width='100%' cellSpacing="0" cellPadding="0" background-color="#FFFFFF">
                           <tr align="left" style={{verticalAlign:"top"}}>
                               <td align="left" style={{verticalAlign:"top",paddingTop:"13px"}} rowSpan="3" width="3"><img src={ProgressBarLeftGif} alt="" width="3" height="16" hspace="0" vspace="0" border="0" colSpan="2"/></td>
                               <td align="left" style={{verticalAlign:"top"}}><img src={ProgressBarTopGif} alt="" width={uploadProgress} height="4" hspace="0" vspace="0" border="0" style={{marginBottom:"-5px"}}/></td>
                           </tr>

                           <tr align="left" style={{verticalAlign:"top"}}>
						   <td align="left" style={{verticalAlign:"top"}}><span><img src={ProgressBarGif} alt="" width={uploadProgress} height="10" hspace="0" vspace="0" border="0"/></span>
                               
                               </td>
                           </tr>

                           <tr align="center" style={{verticalAlign:"top"}}>
                               <td align="center" style={{verticalAlign:"middle"}} className="instructions" colSpan="2">Status: {wsTransmittedReceivedData} bytes of {itotal} sent (at {netSpeed} Kbps)</td>
                           </tr>

                       </table>
                       <table border="0" width='100%' cellSpacing="0" cellPadding="0" >
                       <tr align="center" verticalAlign="top">
                               <td align="left" style={{verticalAlign:"middle"}} className="instructions"><strong>Est Time left:&nbsp;</strong>{formatTime(timeRemaining)}</td>
                               <td align="right" style={{verticalAlign:"middle"}} className="instructions"><strong>Elapsed time:&nbsp;</strong>{formatTime(elapsedTime)}</td>
                           </tr>
                       </table>
                       </td>
                       </tr>
                       </table>
                        }
						
                       <br/>
                       <br/>
                      <button type="submit" style={{margin:"5px"}} >Upload Files</button>

                   </form>
                   </td>
                   </tr>
                   </table>

             </div>
             </div>

             <div style={{display : displayFileSection ? 'block' : 'none', fontSize:'110%', fontFamily:"inherit", position:"relative",width:"100%",height:"100%", overflow:"scroll"}}>
              <table style={{border:"1px solid black",cellPadding:"5",width:"95%",align:"center",marginLeft:"auto",marginRight:"auto",marginTop:"25px"}}>
                <tr>
                <td colSpan="2" style={{backgroundColor:"#0066cc"}}>
                    <font style={{color:"white",size:"+1",align:"center"}}>Files Uploaded</font>
                </td>
                </tr>

                <tr  style={{backgroundColor:"#CCCFF3"}}>
                <td style={{fontSize: "110%",border:"1px solid black"}}>
                    <nobr>File Name</nobr>
                </td>

                <td style={{fontSize: "110%",align:"right",border:"1px solid black"}}>
                    <nobr>File size</nobr>
                </td>
                </tr>
                {successFileTableData}
                </table>
                {/* <p> */}
                    <center> <button onClick={ closeModal } className="closeButton">close</button> </center>

                {/* </p> */}
                
                <div style={{bottom:"30px",width:"100%",position:"static"}}>
                <hr />
                <table align="center">
                <tr><td align="center">File Upload Complete</td></tr>
                    <tr>
                        <td>
                        Status: {ibytes} bytes of {itotal} sent (at {Math.abs(netSpeed)} Kbps)<br />
                            Est time left: 00:00:00<br />
                            Elapsed time: {Math.abs(elapsedTime)}<br />
                        </td>
                    </tr>
                </table>
                </div>
                </div>

         </div>
      </div>
    );
                      
}

const dropzoneStyle ={
   border: '2px dashed #cccccc',
   borderRadius : '4px',
   padding : '40px',
   textAlign : 'center',
   cursor: 'pointer',
   opacity : '0.7',
   boxShadow : '3px 3px 5px 0px rgba(0,0,0,0.3)',
   maxHeight : '100px',
   overflowY : 'auto',
   };

export default BulkUploadPopup;
