import React,{ useEffect, useState } from 'react';
import { Routes, Route, Navigate, useNavigate, useLocation  } from 'react-router-dom';
import { loginRequest, msalConfig } from './authConfig';
import { useMsal } from "@azure/msal-react";
import './App.css';
import Login from './Components/Login';
import Dashboard from './Components/Dashboard/Dashboard';
import HealthCheck from './Components/Dashboard/HealthCheck';
import Modal from './Components/common/Modal';
import SmsPopup from './Components/common/Modal/SMS/SmsPopup';
import Unzip from './Components/common/Modal/Unzip/Unzip';
import FileUpload from './Components/FileUpload';
import UserList  from './Components/User/UserList';
import UserGroup from './Components/User/UserGroup';
import MyHelp from './Components/MyHelp';
import NewFolder from './Components/common/Modal/NewFolder';
import RenameFolder from './Components/common/Modal/RenameFolder';
import BulkUploadPopup from './Components/common/Modal/bulkUploadPopup';
import ErrorBoundary from './Components/common/ErrorBoundary';
import Preview from './Components/common/Modal/Preview';
import DownloadZip from './Components/common/Modal/Preview/DownloadZip';


function Root() {
    const navigate = useNavigate();
    const location = useLocation();
    const [accessToken, setAccessToken] = useState(localStorage.getItem('accessToken'));
    let pathname = location.pathname;
    let [userReqURL,setUserReqURL] = useState('/mdc/auth/media');

    const { instance, accounts } = useMsal(msalConfig);
    const activeAccount = accounts[0];

    useEffect(async () => {
        const fetchData = async () => {
            if (activeAccount) {
                try {
                    await instance.initialize();
                    const response = await instance.acquireTokenSilent({
                        ...loginRequest,
                        account: activeAccount,
                    });
                    localStorage.setItem('accessToken', response.accessToken);
                    let accessStorageToken = localStorage.getItem('accessToken');
                    setAccessToken(accessStorageToken);
                    //const navigateTo = location.pathname === "/" ? '/mdc/auth/media' : location.pathname;
                    // console.log("NavigateTo", navigateTo);
                    //navigate(navigateTo, { state: { userName: accounts[0] ?.name } });
                    if(location.pathname === "/")
                    {
                        navigate('/mdc/auth/media');    
                    }
                } catch (error) {
                    console.error("Authentication error:", error);
                    localStorage.clear();
                    navigate('/');
                }
            }
        };

        fetchData();
    }, [activeAccount, instance, navigate]);

    useEffect(() => {
        if(accessToken !== null && pathname === '/'){
            let redirectTo = userReqURL;
            if(redirectTo === '/')
            {
              redirectTo = '/mdc/auth/media';
            }
            navigate(redirectTo);
        }
        if(accessToken === null)
        {
            setUserReqURL(pathname);
        }
    },[accessToken]);


    return (
        <div>
            <ErrorBoundary>
            <Routes>
                <Route path='/devmsfhealth' element={<HealthCheck />} />
                    {(accessToken !== ''  && accessToken !== null) && (
                        <>
                            <Route exact path={'/mdc/auth/media/v1/*'} element={<MyHelp />} />
                            <Route path='/mdc/auth/media/*' element={<Dashboard />} />
                            <Route path='/mdc/auth/media/*?upload=1' element={<Modal />} />
                            <Route path='/mdc/auth/media/*?bulkupload=1' element={<BulkUploadPopup />} />
                            <Route path='/mdc/auth/media/*?sms_config=1' element={<SmsPopup />} />
                            <Route path='/mdc/auth/media/*?unzip=1' element={<Unzip />} />
                            <Route path='/mdc/auth/media/*?newfolder=1' element={<NewFolder />} />
                            <Route path='/mdc/auth/media/*?rename=1' element={<RenameFolder />} />
                            <Route path='/mdc/preview/*' element={<Preview />} />
                            <Route path='/mdc/download/*' element={<DownloadZip />} />
                            <Route path='/user/list' element={<UserList/>}/>
                            <Route path='/user/list?edituser=1' element={<UserList/>}/>
                            <Route path='/user/add' element={<UserGroup/>}/>
                            <Route path='/fileUpload' element={<FileUpload />} />
                            <Route path='/*' element={<Navigate replace to={`/mdc/auth/media`} />}/>
                        </>
                    )}
                    
                <Route exact path='/' element={<Login redirectUserTo={userReqURL} />} />
                <Route path='/*' element={<Navigate replace to='/' />} />
                
            </Routes>
            </ErrorBoundary>
        </div>
    );
}

export default Root;
