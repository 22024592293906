import React from 'react';
import { HashLink } from 'react-router-hash-link';
import {  useLocation } from 'react-router-dom';
import NamingLogo from '../../images/naming.gif';
import BackButton from "../../images/button_back.gif";
import HomeButton from "../../images/button_home.gif";
import HelpBreadCrumb from "../../images/help_breadcrumb.gif";
import ButtonNewFolder from "../../images/button_new_folder.gif";
import ButtonUpload from '../../images/button_upload.gif';
import pushButton from '../../images/button_push.png';
import buttonUnlocked from '../../images/button_unlocked.gif';
import buttonlocked from '../../images/button_locked.gif';
import renameButton from '../../images/button_rename.gif';
import button_unzip from '../../images/button_unzip.gif';
import ButtonLog from '../../images/button_log.gif';
import Header from '../common/Header/Header';
import Footer from '../common/Footer/Footer';
import TopNav from '../common/Banner/TopNav';

const MyHelp = ({}) => {
    const userName = 'Mediadev.pearson.com - Help';
    const location = useLocation();
    const currentPath = location.pathname;


    return (
      <>
        <Header title={userName}/>
        <body>
        <TopNav  title={userName} currentPath={currentPath} />

        <span className="spacer"><br/><br/></span>

            <table border="0" width="90%" cellSpacing="1" cellPadding="2" className="myTable" align="center">
            <tr align="right" valign="top">
                <td className="helpGreen" align="right">
                
                <table border="0" width="65%" cellSpacing="0" cellPadding="5" align="right">
                    <tr align="left" valign="top">
                        <td align="left" valign="top" colspan="3"><font size="+1" color="#333333">Using the MediaServer Content Propagation Utility</font></td>
                    </tr>
                    <tr align="left" valign="top">
                    <td align="left" valign="top" rowspan="5">&nbsp;</td>
                        <td align="left" valign="top"><HashLink to="#overview">MediaServer Overview</HashLink></td>
                        <td align="left" valign="top"><HashLink to="#sms">Applying SMS Protection</HashLink></td>
                    </tr>
                    <tr align="left" valign="top">
                        <td align="left" valign="top"><HashLink to="#navigation">Navigating the MediaServer</HashLink></td>
                        <td align="left" valign="top"><HashLink to="#pushing">Pushing Files</HashLink></td>
                    </tr>
                    <tr align="left" valign="top">
                        <td align="left" valign="top"><HashLink to="#folders">Creating a New Folder</HashLink></td>
                        <td align="left" valign="top"><HashLink to="#viewing">Viewing Files</HashLink></td>
                    </tr>
                    <tr align="left" valign="top">
                        <td align="left" valign="top"><HashLink to="#uploading">Uploading Files</HashLink></td>
                        <td align="left" valign="top"><HashLink to="#renaming">Renaming Files</HashLink></td>
                    </tr>
                    <tr align="left" valign="top">
                        <td align="left" valign="top"><HashLink to="#unzipping">Unzipping Files</HashLink></td>
                        <td align="left" valign="top"><HashLink to="#deleting">Deleting Files</HashLink></td>
                    </tr>
                </table>
                
                
                </td>
            </tr>
        </table>

        <span className="spacer"><br/><br/><br/></span>

        <section id="overview">
            <table border="0" width="90%" cellSpacing="1" cellPadding="2" align="center" summary="MediaServer Overview">
                <tr align="left" valign="top">
                    <td align="left" valign="top" class="helpSubHead" colspan="2">MediaServer Overview</td>
                </tr>
                <tr align="left" valign="top">
                    <td align="left" valign="top" width="2%">&nbsp;</td>
                    <td align="left" valign="top" class="helpContent">
                        <p>
                        The Pearson Media Server is a hosting facility specifically designed to house media and content files to be used in your online courses.
                        The Media Server consists of two servers: a development server and a live server. Files are uploaded to the development server, then "pushed" to the live server. You may only link to files on the live server.For additional general information on the Media Server, <a href='https://pearsoneducationinc.sharepoint.com/:w:/r/sites/DTCApplications/Shared%20Documents/Legacy%20Services%20Support/Media%20Server/Tech%20Support%20FAQs%20-%20Media%20Server.docx?d=w552f31a1a96544ef8dfce0354991d140&csf=1&web=1&e=je8Jck' target='_blank'>please see these FAQs.</a>
                        </p>
                        {/* <p>The Pearson CMG MediaServer is a hosting facility specifically designed to house media and content files to be used in your online courses. The advantage of using this server is that it greatly decreases the file size of course content cartridges.  Content which is used in CourseCompass, Blackboard, WebCT and even Companion Websites maybe be stored here.</p>

                        <p> The Pearson CMG MediaServer consists of two servers: a development server and a live server. Files are uploaded to the development server, then "pushed" to the live server. You may only link to files on the live server.</p>


                        <p>The following file types can be placed on this server: GIF (.gif), JPEGS (.jpg), PNG (.png), TIFF (.tif), EPS (.eps), Photoshop (.ps), Illustrator (.ai), progressive (non-streaming) audio/video files (.mov, .rm, .wav, .mp3, .mpeg, .wmv, .wma), PDF (.pdf), Acrobat (.pdf), html (e-books), JavaScript (.js), Style Sheets (.css), Java Applets, Flash files (.swf), Shockwave (.dcr), PowerPoint files (.ppt), Microsoft Word files (.doc), Excel files (.xls), RTF Files (.rtf), Chime files (.pdb), TestGen (.tst, .bok), Compressed files (.zip, sit, .sea, .tar). </p> */}
                        <p>EXE files are permitted, but may not be executable and must be zipped.</p>
                        <p>Files that will not work and are not permitted on the MediaServer are:
                        Perl, CGI, JSP, Server Side Includes, ASP, PHP, CFM.</p>
                        <p>Each course on the MediaServer must be titled with a Course Directory Name.
                        The course directory name should follow the naming convention shown below.<br/><img src={NamingLogo} alt="Naming Convention" width="537" height="71" hspace="0" vspace="0" border="0" />
                        </p>
                        <p>You must use common Web authoring guidelines when naming your files: <strong>no spaces, 8-bit characters, or uppercase letters!</strong>  If not, these files will <strong>NOT</strong> work off of this server.</p></td>
                </tr>
                
            </table>
        </section>

        <span class="spacer"><br/><br/><br/></span>

        <section id="navigation">
            <table border="0" width="90%" cellspacing="1" cellpadding="2" align="center" summary="Navigating the MediaServer">
                <tr align="left" valign="top">
                    <td align="left" valign="top" class="helpSubHead" colspan="2">Navigating the MediaServer</td>
                </tr>
                <tr align="left" valign="top">
                    <td align="left" valign="top" width="2%">&nbsp;</td>
                    <td align="left" valign="top" class="helpContent"><p>After you have successfully logged onto the MediaServer, you will be situated at the top level of your imprint. If you are creating a new course directory, here is where you will do so (see <HashLink to="#folders">Creating a New Folder</HashLink>). If you will be uploading files to an existing folder, navigate to that folder by clicking the folder name.</p>
            <p>Clicking the <strong>back button</strong> <img src={BackButton} alt="back button" width="51" height="48" hspace="0" vspace="0" border="0" align="absmiddle"/> will bring you up one level in the directory structure.</p>

            <p>Clicking the <strong>home button</strong> <img src={HomeButton} alt="home" width="51" height="48" hspace="0" vspace="0" border="0" align="absmiddle" /> will bring you to the top level of your imprint.</p>

            <p>You may also navigate by clicking the folder names in the <strong>Current Location</strong> bread crumb trail:<br/>
            <img src={HelpBreadCrumb} alt="breadcrumb trail" width="321" height="43" hspace="0" vspace="10" border="0" /></p></td>
                </tr>
            </table>
        </section>


        <span class="spacer"><br/><br/><br/></span>

        <section id="folders">
            <table border="0" width="90%" cellspacing="1" cellpadding="2" align="center" summary="Creating a New Folder">
                <tr align="left" valign="top">
                    <td align="left" valign="top" class="helpSubHead" colspan="2">Creating a New Folder</td>
                </tr>
                <tr align="left" valign="top">
                    <td align="left" valign="top" width="2%">&nbsp;</td>
                    <td align="left" valign="top" class="helpContent"><p>To create a new folder on the MediaServer, simply click the <strong>new folder</strong> button<img src={ButtonNewFolder} alt="new folder" width="54" height="48" hspace="0" vspace="0" border="0" align="absmiddle" /> located in the upper left-hand of your screen.</p>

            <p> A new window will launch, prompting you to enter a name for the folder in the text field. Click the <strong>Create Folder</strong> button and your main browser window will refresh, displaying the new folder you have just created.</p></td>
                </tr>
            </table>
        </section>

        <span class="spacer"><br/><br/><br/></span>

        <section id="uploading">

            <table border="0" width="90%" cellspacing="1" cellpadding="2" align="center" summary="Uploading Files">
                <tr align="left" valign="top">
                    <td align="left" valign="top" class="helpSubHead" colspan="2">Uploading Files</td>
                </tr>
                <tr align="left" valign="top">
                    <td align="left" valign="top" width="2%">&nbsp;</td>
                    <td align="left" valign="top" class="helpContent"><p>The MediaServer allows users to upload as many as eight files in one pass. The maximum combined size of those eight files may not exceed 50Mb.</p>
                        <p>To begin uploading files, navigate to the directory where you would like to begin posting files. Next, click the <strong>upload</strong> button <img src={ButtonUpload} alt="upload" width="51" height="48" hspace="4" vspace="0" border="0" align="middle" /> located in the upper left-hand corner. The <strong>File Uploader</strong> window will open. Click the "Browse/Choose File" button next to line number 1. Next, locate the file on your hard drive that you wish to upload. Continue this process for the remaining lines.</p>
                        <p>Finally, click the <strong>Upload Files</strong> button to begin the upload process. A progress bar will appear in the bottom frame of the <strong>File Uploader</strong> window indicating the status of your upload. When the upload has completed, click "close" to return to your main browser window.</p>

                        <p>If you choose to upload a ZIP file containing multiple files and folders, you may do so in the <strong>File Uploader</strong> window. See <HashLink to="#unzipping">Unzipping Files</HashLink> for more details.</p></td>
                </tr>
            </table>
        </section>

        <span class="spacer"><br/><br/><br/></span>

        <section id="unzipping">
            <table border="0" width="90%" cellspacing="1" cellpadding="2" align="center" summary="Unzipping Files">
                <tr align="left" valign="top">
                    <td align="left" valign="top" class="helpSubHead" colspan="2">Unzipping Files</td>
                </tr>
                <tr align="left" valign="top">
                    <td align="left" valign="top" width="2%">&nbsp;</td>
                    <td align="left" valign="top" class="helpContent"><p>Since the MediaServer only allows for uploading eight files simultaneously, it may be more beneficial to ZIP your entire directory or group of directories and files, and upload the single ZIP file (the ZIP file can not exceed 50Mb in size).</p>
                        <p>After you have uploaded your ZIP file, an <strong>unzip</strong> icon<img src={button_unzip} alt="unzip" width="18" height="21" hspace="4" vspace="0" border="0" align="absmiddle" /> will appear under the unzip column to the right of your file name. Clicking the <strong>unzip</strong> icon will prompt the following message:</p>

                        <blockquote><strong>Warning! You have chosen to unzip [file] in the [name] directory. Unzipping this file will expand all files contained within this zip into this directory. Do you wish to proceed?</strong></blockquote>

                        <p>Click "unzip" to proceed. Depending on the size and complexity of the ZIP file, the unzipping process could take 1-10 minutes to complete. To check the status of the unzipping process, click on the <strong>log</strong> button.</p>

                        <p>Once the unzipping process is complete and you have check to see that all of your files have been properly unloaded, you may delete the original ZIP file (see <HashLink to="#deleting">Deleting Files</HashLink>).</p></td>
                </tr>
            </table>
        </section>

        <span class="spacer"><br/><br/><br/></span>

        <section id="sms">

            <table border="0" width="90%" cellspacing="1" cellpadding="2" align="center" summary="Applying SMS Protection">
                <tr align="left" valign="top">
                    <td align="left" valign="top" class="helpSubHead" colspan="2">Applying SMS Protection</td>
                </tr>
                <tr align="left" valign="top">
                    <td align="left" valign="top" width="2%">&nbsp;</td>
                    <td align="left" valign="top" class="helpContent"><p>You may apply SMS protection to any file or folder on the MediaServer. To do so, click the <strong>open lock</strong> icon <img src={ buttonUnlocked} alt="lock" width="18" height="14" hspace="2" vspace="0" border="0" align="absmiddle"/>to the left of the file/folder name. This will launch the SMS Configuration window. Complete the SMS configuration by entering the required information, then click <strong>Save Changes</strong>.</p>
                        <p>The SMS Configuration window will close and the icon next to the file/folder will become a <strong>locked</strong> icon <img src={ buttonlocked } alt="" width="18" height="14" hspace="2" vspace="0" border="0" align="absmiddle" />, indicating SMS has been applied.</p>

                        <p>In order for the SMS configuration to apply to the live server, you must <strong>push</strong> the file/folder (see <HashLink to="#pushing">Pushing Files</HashLink>). Once SMS has been applied to a folder, all items within that folder will inherit those SMS settings. You may override those inherited setting for any file/folder by simply clicking the  <strong>locked</strong> icon <img src={ buttonlocked } alt="" width="18" height="14" hspace="2" vspace="0" border="0" align="absmiddle" />.</p>
                        <p>You may also remove SMS settings at any time by clicking the  <strong>locked</strong> icon <img src={ buttonlocked } alt="" width="18" height="14" hspace="2" vspace="0" border="0" align="absmiddle" />, which will launch the SMS Configuration window. Choose the <strong>disable</strong> radio button, then click <strong>Save Changes</strong>.
                        The window will close and the icon will change to the <strong>open lock</strong> icon <img src={{ buttonUnlocked }} alt="lock" width="18" height="14" hspace="2" vspace="0" border="0" align="absmiddle" /> indicating no SMS protection. Remember to <strong>push</strong> the file again after removing the SMS protection.</p></td>
                </tr>
            </table>
        </section>

        <span class="spacer"><br/><br/><br/></span>

        <section id="pushing">
            <table border="0" width="90%" cellspacing="1" cellpadding="2" align="center" summary="Pushing Files">
                <tr align="left" valign="top">
                    <td align="left" valign="top" class="helpSubHead" colspan="2">Pushing Files</td>
                </tr>
                <tr align="left" valign="top">
                    <td align="left" valign="top" width="2%">&nbsp;</td>
                    <td align="left" valign="top" class="helpContent"><p>To promote a file or directory to the live server, click the <strong>push</strong> button located to the far right of your file or folder name.<img src={ pushButton} alt="" width="50" height="14" hspace="5" vspace="0" border="0" align="absmiddle"/></p>
                        <p>Keep in mind you can promote entire directories as well as individual files. <strong>Important note:</strong> if you have created a new course directory folder, the first time you promote to the live server you must "push" the entire directory. Otherwise, if you promote a subdirectory, it will not transfer to the live server.</p>

                        <p><font color="#660000"><strong>Warning!</strong> If you promote the entire course directory, it will overwrite all files on the live server in that same course directory.</font></p>

                        <p>Example: If you have a folder titled pe_myself_mycourse_1 on the development server that contains no files, but you already have the same folder on the live server that does contains files, pushing the empty folder from the development server will overwrite and erase the files on the live server.</p>

                        <p>Once you have successfully pushed a file or folder, you will see the confirmation message in the upper right of the window. This message will also contain the live URL to the file (or folder) that you may copy to paste for use in your online courses.</p>

                        <p>You may also view the log file by clicking the log button <img src={ ButtonLog } alt="" width="51" height="48" hspace="0" vspace="0" border="0" align="middle" />. The log will indicate if the files were pushed successfully, or if there was an error.</p>
                        </td>
                </tr>
            </table>
        </section>

        <span class="spacer"><br/><br/><br/></span>

        <section id="viewing">
            <table border="0" width="90%" cellspacing="1" cellpadding="2" align="center" summary="Viewing Files">
                <tr align="left" valign="top">
                    <td align="left" valign="top" class="helpSubHead" colspan="2">Viewing Files</td>
                </tr>
                <tr align="left" valign="top">
                    <td align="left" valign="top" width="2%">&nbsp;</td>
                    <td align="left" valign="top" class="helpContent"><p>To view any file on the MediaServer, simply click on the file name to launch a new browser window containing the file. Next, using your browser's menu options, view the address bar to see the MediaServer development URL. Remove the <b>"dev"</b> in the URL http://media<b>dev</b>.pearsoncmg.com to access the live URL of the file (assuming you have previously <HashLink to="#pushing">pushed</HashLink> the file).</p></td>
                </tr>
            </table>
        </section>

        <span class="spacer"><br/><br/><br/></span>

        <section id="renaming">
            <table border="0" width="90%" cellspacing="1" cellpadding="2" align="center" summary="Renaming Files">
                <tr align="left" valign="top">
                    <td align="left" valign="top" class="helpSubHead" colspan="2">Renaming Files</td>
                </tr>
                <tr align="left" valign="top">
                    <td align="left" valign="top" width="2%">&nbsp;</td>
                    <td align="left" valign="top" class="helpContent"><p>To rename a file or folder, click the <strong>rename</strong> icon <img src={ renameButton } alt="rename" width="14" height="19" hspace="4" vspace="0" border="0" align="absmiddle" />located to the right of the file/folder under the rename column.</p>

                    <p>A new browser window will spawn showing the previous file/folder name in the text field. Rename your file or folder, then click the <strong>Rename</strong> button. Your main browser window will refresh, displaying the newly named file or folder.</p></td>
                </tr>
            </table>
        </section>

        <span class="spacer"><br/><br/><br/></span>

        <section id="deleting">
            <table border="0" width="90%" cellspacing="1" cellpadding="2" align="center" summary="Deleting Files">
                <tr align="left" valign="top">
                    <td align="left" valign="top" class="helpSubHead" colspan="2">Deleting Files</td>
                </tr>
                <tr align="left" valign="top">
                    <td align="left" valign="top" width="2%">&nbsp;</td>
                    <td align="left" valign="top" class="helpContent"><p>To permanently remove outdated or unwanted files from the MediaServer, click on the check boxes to the left of the file names, then click the <strong>delete selected</strong> button located at the bottom. An alert box will confirm that you choose to delete the selected files. Clicking <strong>OK</strong> will permanently delete these files.</p>
            <p>Note: a folder may not be deleted if there are any files located within it. To delete a folder, you must first delete all the files inside of it by clicking <strong>select all</strong>, then clicking <strong>delete selected</strong>. Once all of the files have been deleted, click the <strong>back</strong> button, and then you may delete that folder.</p></td>
                </tr>
            </table>
        </section>

        <span class="spacer"><br/><br/><br/></span>


        <span class="spacer"><br/><br/></span>
        <Footer/>
        </body>
    </>
  )
}

export default MyHelp;