import React, { useEffect, useState } from 'react'
import './Login.css'
import { login } from './actions';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import config from '../../config';
import { SsoRedirect } from './ssoRedirect';

const Login = (props) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      dispatch(
        login({
          email: email,
          password: password
        })
      );
    } catch (err) {
      console.log(err.message)
    }
  }

  const loginform = () => {

    return (
      <div className="logincontainer">
        <div className="loginform">
          <h1>Login</h1>
          <form onSubmit={handleSubmit}>
            <label htmlFor="">Email</label> <br />
            <input type="text" placeholder='Email' value={email} onChange={(e) => { setEmail(e.target.value) }} /><br />
            <label htmlFor="">Password</label> <br />
            <input type="password" placeholder='Password' value={password} onChange={(e) => { setPassword(e.target.value) }} /><br />
            <button className='loginbtn' type='submit'>Login</button><br />
          </form>
        </div>
      </div>
    )
  }

  if (config.mode === 'sso') {
    return (
      <div>
        {SsoRedirect(props)}
      </div>
    )
  } else {
    return (
      <div>
        {loginform()}
      </div>
    )
  }
}

export default Login